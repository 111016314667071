import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { PrevNext, TagTextList, MetaData } from "components/molecules"
import { Layout } from "components/pageLayouts"
import styles from "./styles.module.scss"

const BlogPostTemplate = ({ data, pageContext }) => {
    const post = data.markdownRemark
    const { previous, next } = pageContext

    let previousLink = ""
    let previousTitle = ""
    let nextLink = ""
    let nextTitle = ""

    if (previous) {
        previousLink = previous.fields.slug
        previousTitle = previous.frontmatter.title
    }

    if (next) {
        nextLink = next.fields.slug
        nextTitle = next.frontmatter.title
    }

    return (
        <Layout>
            <div>
                <div className={"row justify-content-center"}>
                    <div className={"col-lg-8"}>
                        <TagTextList tags={post.frontmatter.tags} />
                        <h1>{post.frontmatter.title}</h1>
                    </div>
                </div>
                {post.frontmatter.img ? (
                    <Img
                        className={styles.img}
                        fluid={post.frontmatter.img.childImageSharp.fluid}
                    />
                ) : (
                    ""
                )}

                <div className={`${styles.metaData}`}>
                    <MetaData
                        author={post.frontmatter.author}
                        date={post.frontmatter.date}
                        big
                    />
                </div>
                <div className={"row justify-content-center"}>
                    <div
                        className={`col-lg-6 ${styles.content}`}
                        dangerouslySetInnerHTML={{ __html: post.html }}
                    />
                </div>
            </div>

            <div className={`${styles.prevNextContainer}`}>
                <PrevNext
                    previousLink={previousLink}
                    previousTitle={previousTitle}
                    nextLink={nextLink}
                    nextTitle={nextTitle}
                />
            </div>
        </Layout>
    )
}

export default BlogPostTemplate

export const pageQuery = graphql`
    query BlogPostBySlug($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            id
            excerpt(pruneLength: 160)
            html
            frontmatter {
                author
                title
                date(formatString: "MMMM DD, YYYY")
                description
                tags
                img {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`
